import Vue from 'vue';
import VueTooltip from 'v-tooltip';

Vue.use(VueTooltip);

// workaround bootstrap tooltip class
// https://github.com/Akryum/v-tooltip/issues/222
VueTooltip.options.defaultTemplate =
  '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>';
VueTooltip.options.defaultArrowSelector =
  '.tooltip-vue-arrow, .tooltip-vue__arrow';
VueTooltip.options.defaultInnerSelector =
  '.tooltip-vue-inner, .tooltip-vue__inner';
