<template>
  <button
    :class="className"
    :type="type"
    :disabled="shouldDisable"
    v-on="$listeners"
  >
    <slot />

    <slot name="icon">
      <spinner
        v-if="loading"
        size="small"
        line-fg-color="#666666"
        class="ml-2"
      />
    </slot>
  </button>
</template>

<script>
import Spinner from 'vue-simple-spinner/src/components/Spinner.vue';

export default {
  name: 'DfButton',
  components: {
    Spinner
  },
  props: {
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    type: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    sizes: {
      type: String,
      default: 'md'
    },
    customClass: {
      type: String,
      default: ''
    },
    removeWhitespaceWrap: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shouldDisable() {
      return this.loading || this.disabled;
    },
    className() {
      const baseStyle = [
        'flex justify-center items-center transition-ease-out transition-all duration-400 py-2.5 gap-2.5 h-11 font-normal',
        !this.removeWhitespaceWrap && 'whitespace-nowrap'
      ]
        .filter(Boolean)
        .join(' ');

      const variantClasses = {
        primary: [
          this.sizes === 'md' ? 'min-w-36 px-6' : '',
          this.shouldDisable
            ? 'text-black-100 bg-black-50 cursor-not-allowed min-w-36'
            : 'bg-primary hover:bg-info-hover text-white'
        ],
        outlined: [
          this.sizes === 'md' ? 'min-w-36 px-6' : '',
          this.shouldDisable
            ? 'border border-black-100 bg-transparent text-black-100'
            : 'border border-primary hover:border-info-hover bg-transparent text-primary'
        ],
        text: [
          'min-w-auto px-0',
          this.shouldDisable
            ? 'text-black-100 min-w-auto'
            : 'text-primary hover:text-info-hover min-w-auto'
        ]
      };

      const variantStyle = variantClasses[this.variant] || [];
      const combinedClasses = [baseStyle, ...variantStyle, this.customClass];

      return combinedClasses.join(' ').trim();
    }
  }
};
</script>
