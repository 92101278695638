<template>
  <div id="app">
    <global-notification />
    <router-view />
  </div>
</template>

<script>
import GlobalNotification from '@/components/common/global-notification.vue';
import domainConfiguration from '@/config/services/domains';
export default {
  name: 'App',
  components: {
    GlobalNotification
  },
  created() {
    const favicon = document.getElementById('favicon');
    favicon.href = `${domainConfiguration.logoSquare}`;
  }
};
</script>
