import { authStore } from '@/stores/auth';
import api from '@/utils/api-services';

export default {
  name: 'Sardine',
  setup() {
    const auth = authStore();
    return { auth };
  },
  mounted() {
    let isSandbox = process.env.VUE_APP_ENVIRONMENT !== 'production';
    let sardineHost = isSandbox ? 'api.sandbox.sardine.ai' : 'api.sardine.ai';
    let uuid = this.auth.user.ss_id;
    let customer_id = this.auth.user.id;
    (async function () {
      let loader = document.createElement('script');
      loader.type = 'text/javascript';
      loader.async = true;
      loader.src = `https://${sardineHost}/assets/loader.min.js`;
      loader.onload = function () {
        window._Sardine.createContext({
          clientId: process.env.VUE_APP_SARDINE_CLIENT_ID,
          sessionKey: uuid,
          userIdHash: customer_id,
          flow: location.pathname,
          environment: isSandbox ? 'sandbox' : 'production',
          parentElement: document.body
        });
      };
      let s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(loader, s);
    })();
    this.sardineLogin();
  },
  methods: {
    async sardineLogin() {
      await api.riskCheck();
    }
  }
};
