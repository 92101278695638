import { getDomainTitle } from '@/utils/get-domain-title';
const domainTitle = getDomainTitle();

export default [
  {
    path: '/register',
    name: 'register',
    component: () => import('@/components/auth/register.vue'),
    meta: {
      title: `${domainTitle} - Create an Account`
    }
  },

  // ERC Loan Users
  {
    path: '/erc-loan/register',
    name: 'erc-registration',
    component: () => import('@/components/auth/register.vue'),
    meta: {
      title: `${domainTitle} - Create an Account`
    }
  },
  {
    path: '/email/verify',
    name: 'verify',
    component: () => import('@/components/auth/verify-email.vue'),
    meta: {
      title: `Email Verification - ${domainTitle}`
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/auth/login.vue'),
    meta: {
      title: `${domainTitle} - Login`
    }
  },
  {
    path: '/password/forgot',
    name: 'forgetPassword',
    component: () => import('@/components/auth/forgot-password.vue'),
    meta: {
      title: `${domainTitle} - Forgot Password`
    }
  },
  {
    path: '/password/reset/:token',
    name: 'resetPassword',
    component: () => import('@/components/auth/reset-password.vue'),
    meta: {
      title: `${domainTitle} - Reset Password`
    }
  },
  {
    path: '/email/verify/resent',
    name: 'resendExpiredVerification',
    component: () => import('@/components/auth/expired-verification.vue'),
    meta: {
      title: `${domainTitle} - Verify Email`
    }
  }
];
