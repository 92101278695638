const domains = {
  default: {
    zendesk: true,
    help: true,
    register: true,
    forgotPassword: true,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/svg/logo-dashfi-square.svg'),
    logo: require('@/assets/images/svg/logo-dashfi.svg'),
    logoHeight: 'h-8',
    name: 'DashFi'
  },
  'app.dash.test': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/svg/logo-sol-cards-square.svg'),
    logo: require('@/assets/images/svg/logo-sol-cards.svg'),
    logoHeight: 'h-16',
    name: 'Sol Media'
  },
  'demo-staging.funneldash.com': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/svg/logo-sol-cards-square.svg'),
    logo: require('@/assets/images/svg/logo-sol-cards.svg'),
    logoHeight: 'h-16',
    name: 'Sol Media'
  },
  'solmedia.cards': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/svg/logo-sol-cards-square.svg'),
    logo: require('@/assets/images/svg/logo-sol-cards.svg'),
    logoHeight: 'h-16',
    name: 'Sol Media'
  },
  'travelport.dash.fi': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-travelport-square.png'),
    logo: require('@/assets/images/svg/logo-travelport.svg'),
    logoHeight: 'h-12',
    name: 'Travelport'
  },
  'travelportcard.com': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-travelport-square.png'),
    logo: require('@/assets/images/svg/logo-travelport.svg'),
    logoHeight: 'h-12',
    name: 'Travelport'
  },
  'joor.dash.fi': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-joor-square.png'),
    logo: require('@/assets/images/svg/logo-joor.svg'),
    logoHeight: 'h-16',
    name: 'JOOR'
  },
  'joorcard.com': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-joor-square.png'),
    logo: require('@/assets/images/svg/logo-joor.svg'),
    logoHeight: 'h-16',
    name: 'JOOR'
  },
  'reddit.dash.fi': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/svg/logo-reddit-square.svg'),
    logo: require('@/assets/images/svg/logo-reddit.svg'),
    logoHeight: 'h-24',
    name: 'Reddit'
  },
  'wayflyer.dash.fi': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-wayflyer-square.png'),
    logo: require('@/assets/images/svg/logo-wayflyer.svg'),
    logoHeight: 'h-16',
    name: 'Wayflyer'
  },
  'wayflyercard.com': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-wayflyer-square.png'),
    logo: require('@/assets/images/svg/logo-wayflyer.svg'),
    logoHeight: 'h-16',
    name: 'Wayflyer'
  },
  'pipe.dash.fi': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/svg/logo-pipe-square.svg'),
    logo: require('@/assets/images/svg/logo-pipe.svg'),
    logoHeight: 'h-24',
    name: 'Pipe'
  },
  'adroll.dash.fi': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-adroll-square.png'),
    logo: require('@/assets/images/svg/logo-adroll.svg'),
    logoHeight: 'h-16',
    name: 'AdRoll'
  },
  'ridgecard.co': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-ridge-co-square.png'),
    logo: require('@/assets/images/svg/logo-ridge-co.svg'),
    logoHeight: 'h-16',
    name: 'Ridge'
  },
  'fxmcard.com': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-fmx.png'),
    logo: require('@/assets/images/logo-fmx.png'),
    logoHeight: 'h-24',
    name: 'FXM'
  },
  'giddyupcard.com': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/svg/logo-giddyup.svg'),
    logo: require('@/assets/images/svg/logo-giddyup.svg'),
    logoHeight: 'h-24',
    name: 'GiddyUp'
  },
  'kartacard.com': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-karta-io.png'),
    logo: require('@/assets/images/logo-karta-io.png'),
    logoHeight: 'h-24',
    name: 'KARTA'
  },
  'pipecard.co': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/svg/logo-pipe-square.svg'),
    logo: require('@/assets/images/svg/logo-pipe.svg'),
    logoHeight: 'h-24',
    name: 'Pipe'
  },
  'redditcard.com': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/svg/logo-reddit-square.svg'),
    logo: require('@/assets/images/svg/logo-reddit.svg'),
    logoHeight: 'h-24',
    name: 'Reddit'
  },
  'theshippingcard.com': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-71lbs.png'),
    logo: require('@/assets/images/logo-71lbs.png'),
    logoHeight: 'h-24',
    name: '71lbs'
  },
  'coretrustcard.co': {
    zendesk: false,
    help: false,
    register: false,
    forgotPassword: false,
    cardImage: '@/assets/images/adcard-bg.png',
    logoSquare: require('@/assets/images/logo-coretrust-square.png'),
    logo: require('@/assets/images/logo-coretrust.png'),
    logoHeight: 'h-24',
    name: 'CoreTrust'
  }
};

const getDomainConfig = (domain) => {
  return domains[domain] ?? domains.default;
};

export default getDomainConfig(window.location.hostname);
