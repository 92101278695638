import { getDomainTitle } from '@/utils/get-domain-title';
const domainTitle = getDomainTitle();

export default [
  {
    path: '/card',
    component: () => import('@/components/adcard'),
    children: [
      {
        path: '/',
        name: 'CardDashboard',
        component: () => import('@/components/adcard/adcard-dashboard.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: '/card',
        redirect: '/'
      },
      {
        path: 'cards',
        name: 'Cards',
        component: () => import('@/components/adcard/adcard-cards.vue'),
        meta: {
          title: `Cards - ${domainTitle}`
        }
      },
      {
        path: 'transactions',
        name: 'CardTransactions',
        component: () => import('@/components/adcard/adcard-transactions.vue'),
        meta: {
          title: `Transactions - ${domainTitle}`
        }
      },
      {
        path: 'payments',
        name: 'CardPayments',
        component: () => import('@/components/adcard/adcard-payments.vue'),
        meta: {
          title: `Payments - ${domainTitle}`
        }
      },
      {
        path: 'insights',
        name: 'CardInsights',
        component: () => import('@/components/adcard/adcard-insights.vue'),
        meta: {
          title: `Insights - ${domainTitle}`
        }
      },
      {
        path: 'rewards',
        name: 'CardRewards',
        component: () =>
          import('@/components/adcard/rewards/adcard-rewards.vue'),
        meta: {
          title: `Rewards - ${domainTitle}`
        }
      }
    ]
  }
];
