export default [
  {
    path: '/:pathMatch(.*)*',
    name: 'not found',
    component: () => import('@/views/errors/not-found.vue'),
    meta: {
      title: '404 Not Found'
    }
  }
];
