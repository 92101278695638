import Vue from 'vue';
Vue.config.devtools = true;
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueCompositionAPI from '@vue/composition-api';
import VueTooltip from 'v-tooltip';
import VueSweetalert2 from 'vue-sweetalert2';
import domainConfiguration from '@/config/services/domains';

import router from './router';

import './assets/tailwind.css';
import './config/plugins';
import 'sweetalert2/dist/sweetalert2.min.css';
import { BrowserTracing } from '@sentry/tracing';
import Zendesk from '@dansmaculotte/vue-zendesk';
import http from './config/services/http';
import ServiceBell from '@servicebell/widget';

// Global components
import AutoLogout from '@/components/common/auto-logout/index.vue';
import Sardine from '@/config/plugins/sardine';

// To be used in desired components by inserting this - <auto-logout />
Vue.component('AutoLogout', AutoLogout);

// To be used in desired components by inserting this - <sardine />
Vue.component('Sardine', Sardine);

Vue.use(VueCompositionAPI);
Vue.use(PiniaVuePlugin);

Vue.use(VueTooltip);
VueTooltip.options.defaultTemplate =
  '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>';
VueTooltip.options.defaultArrowSelector =
  '.tooltip-vue-arrow, .tooltip-vue__arrow';
VueTooltip.options.defaultInnerSelector =
  '.tooltip-vue-inner, .tooltip-vue__inner';

Vue.use(VueSweetalert2, {
  confirmButtonColor: '#3A71E6',
  heightAuto: false
});

// Using a conditional to ensure it doesn't load during E2E tests
if (!window.isReflectTest) {
  Vue.use(Zendesk, {
    key: process.env.VUE_APP_ZENDESK_KEY,
    hideOnLoad: window.location.pathname.includes('apply'),
    disabled: !domainConfiguration.zendesk,
    settings: {
      webWidget: {
        position: {
          horizontal: 'left',
          vertical: 'bottom'
        }
      }
    }
  });
}

// Using a conditional to ensure it doesn't load during E2E tests
if (!window.isReflectTest) {
  // Hidden set to true to prevent automatic loading.
  // To be used in desired components by inserting this - this.$ServiceBell('show')
  ServiceBell('init', process.env.VUE_APP_SERVICEBELL_KEY, {
    hidden: true,
    position: 'left'
  });
  Vue.prototype.$ServiceBell = ServiceBell;
}

Vue.prototype.$http = http;
Vue.config.productionTip = false;

// Using a conditional to ensure it doesn't load during E2E tests
if (!window.isReflectTest) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          ...process.env.VUE_APP_SENTRY_ORIGINS.split(','),
          /^\//
        ]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_ENVIRONMENT,
    ignoreErrors: ['ResizeObserver loop limit exceeded']
  });
}

new Vue({
  router,
  validations: {},
  pinia: createPinia(),
  render: (h) => h(App)
}).$mount('#app');
