import { defineStore } from 'pinia';

export const authStore = defineStore('auth', {
  state: () => ({
    user: null,
    isAuthenticated: false
  }),
  actions: {
    setUser(user, isAuthenticated) {
      this.user = user;
      this.isAuthenticated = isAuthenticated;
    }
  }
});
