import { getDomainTitle } from '@/utils/get-domain-title';
const domainTitle = getDomainTitle();

export default [
  {
    path: '/apply',
    component: () => import('@/components/onboarding'),
    children: [
      {
        path: 'qualification',
        name: 'Qualification',
        component: () =>
          import('@/components/onboarding/qualification-question.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: '/apply',
        redirect: 'qualification'
      },
      {
        path: 'rejection',
        name: 'Rejection',
        component: () =>
          import('@/components/onboarding/rejected-qualification.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: 'plaid-verification',
        name: 'Plaid Verification',
        component: () => import('@/components/onboarding/plaid-idv.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: 'business-details',
        name: 'Business Details',
        component: () =>
          import('@/components/onboarding/business-details/index.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: 'terms-and-conditions',
        name: 'Terms and Conditions',
        component: () =>
          import('@/components/onboarding/terms-and-conditions.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: 'onboarding-activation',
        name: 'Onboarding Activation',
        component: () =>
          import('@/components/onboarding/onboarding-activation.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      },
      {
        path: '/pending',
        redirect: 'onboarding-activation'
      },
      {
        path: 'rejected-application',
        name: 'Rejected Application',
        component: () =>
          import('@/components/onboarding/rejected-application.vue'),
        meta: {
          title: `Dashboard - ${domainTitle}`
        }
      }
    ]
  }
];
