import { render, staticRenderFns } from "./global-notification.vue?vue&type=template&id=1cad7558&"
import script from "./global-notification.vue?vue&type=script&lang=js&"
export * from "./global-notification.vue?vue&type=script&lang=js&"
import style0 from "./global-notification.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports