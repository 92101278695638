<template>
  <transition name="fade">
    <div
      class="notification"
      v-if="notification.show"
      :class="notificationClasses"
      @click="closeNotification"
    >
      <div
        class="icon"
        v-if="notification.type == 'success'"
        dusk="success-notification"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2ZM12.492 5.661L7.2 10.953L5.508 9.261C5.157 8.91 4.59 8.91 4.239 9.261C3.888 9.612 3.888 10.179 4.239 10.53L6.57 12.861C6.921 13.212 7.488 13.212 7.839 12.861L13.77 6.93C14.121 6.579 14.121 6.012 13.77 5.661C13.419 5.31 12.843 5.31 12.492 5.661Z"
            fill="#1FD387"
          />
        </svg>
      </div>
      <div
        class="icon"
        v-if="notification.type == 'error'"
        dusk="error-notification"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.009 18.0001C13.977 18.0001 18 13.9681 18 9.00006C18 4.03206 13.977 6.32638e-05 9.009 6.36605e-05C4.032 6.40579e-05 1.90735e-06 4.03206 1.90735e-06 9.00006C1.90735e-06 13.9681 4.032 18.0001 9.009 18.0001ZM9 1.80006C12.978 1.80006 16.2 5.02206 16.2 9.00006C16.2 12.9781 12.978 16.2001 9 16.2001C5.022 16.2001 1.8 12.9781 1.8 9.00006C1.8 5.02206 5.022 1.80006 9 1.80006Z"
            fill="#E04F64"
          />
          <path
            d="M5.60872 10.9287C5.2587 11.2788 5.2587 11.8515 5.60872 12.2015C5.95874 12.5516 6.5315 12.5516 6.88151 12.2015L12.1067 6.97639C12.4567 6.62638 12.4567 6.05362 12.1067 5.7036C11.7566 5.35358 11.1839 5.35358 10.8339 5.7036L5.60872 10.9287Z"
            fill="#E04F64"
          />
          <path
            d="M10.834 12.2016C11.184 12.5516 11.7568 12.5516 12.1068 12.2016C12.4568 11.8516 12.4568 11.2788 12.1068 10.9288L6.88162 5.70367C6.5316 5.35366 5.95885 5.35366 5.60883 5.70367C5.25881 6.05369 5.25881 6.62645 5.60883 6.97647L10.834 12.2016Z"
            fill="#E04F64"
          />
        </svg>
      </div>
      <div
        class="icon"
        v-if="notification.type == 'warning'"
        dusk="warning-notification"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 13.5C8.505 13.5 8.1 13.095 8.1 12.6L8.1 9C8.1 8.505 8.505 8.1 9 8.1C9.495 8.1 9.9 8.505 9.9 9L9.9 12.6C9.9 13.095 9.495 13.5 9 13.5ZM9.009 18C13.977 18 18 13.968 18 9C18 4.032 13.977 -1.55565e-06 9.009 -1.12133e-06C4.032 -6.86227e-07 6.86227e-07 4.032 1.12054e-06 9C1.55486e-06 13.968 4.032 18 9.009 18ZM9 1.8C12.978 1.8 16.2 5.022 16.2 9C16.2 12.978 12.978 16.2 9 16.2C5.022 16.2 1.8 12.978 1.8 9C1.8 5.022 5.022 1.8 9 1.8ZM8.1 4.5L9.9 4.5L9.9 6.3L8.1 6.3L8.1 4.5Z"
            fill="#F5AA5E"
          />
        </svg>
      </div>
      <div class="message">
        {{ notification.message }}
      </div>
    </div>
  </transition>
</template>

<script>
import { notifyStore } from '@/stores/notification';

export default {
  data() {
    return {
      show: false,
      message: '',
      type: '',
      timeout: null
    };
  },
  setup() {
    const notification = notifyStore();
    return { notification };
  },
  computed: {
    notificationClasses: function () {
      const typeClasses = {
        error: 'error',
        warning: 'warning'
      };
      return typeClasses[this.notification.type] || 'success';
    }
  },
  methods: {
    closeNotification() {
      this.notification.closeNotification();
    }
  }
};
</script>
<style lang="scss">
.notification {
  position: absolute;
  top: 4rem;
  right: 0;
  z-index: 10000;
  max-width: 40rem;
  min-width: 16rem;
  border-radius: 0.25rem;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(8, 19, 57, 0.1);
  cursor: default;

  &.success {
    @apply text-greenbase;
  }

  &.error {
    @apply text-error-base;
  }

  &.warning {
    @apply text-orange-650;
  }

  @media (max-width: 40rem) {
    left: 1rem;
    right: 1rem;
    transform: none;
    padding: 1rem;
  }

  .icon {
    width: 18px;
    height: 18px;
    fill: currentColor;
  }
}
</style>
