import { defineStore } from 'pinia';

export const notifyStore = defineStore('notification', {
  state: () => ({
    show: false,
    message: '',
    type: ''
  }),
  actions: {
    showNotification(message, type) {
      this.show = true;
      this.message = message;
      this.type = type;
      setTimeout(() => {
        this.closeNotification();
      }, 5000);
    },
    closeNotification() {
      this.message = '';
      this.type = '';
      this.show = false;
    }
  }
});
