import { getDomainTitle } from '@/utils/get-domain-title';
const domainTitle = getDomainTitle();

export default [
  {
    path: '/pay',
    component: () => import('@/components/adpay'),
    children: [
      {
        path: '/',
        name: 'AdpayOverview',
        component: () => import('@/components/adpay/overview.vue'),
        meta: {
          title: `Adpay Overview - ${domainTitle}`
        }
      },
      {
        path: 'payments',
        name: 'AdpayPayments',
        component: () => import('@/components/adpay/payments.vue'),
        meta: {
          title: `AdPay from ${domainTitle}`
        }
      },
      {
        path: 'payments/details/:id',
        name: 'AdpayPayments',
        component: () => import('@/components/adpay/payment-details.vue'),
        meta: {
          title: `AdPay from ${domainTitle}`
        }
      }
    ]
  },
  {
    path: '/pay/pending',
    name: 'PayPending',
    component: () => import('@/components/adpay/pending.vue'),
    meta: {
      title: `AdPay from ${domainTitle}`
    }
  }
];
